import React, { Component } from "react";
import { PaginationFilterTable } from "../../common/PaginationFilterTable";
import {
  searchAseetDwell,
  getDefaultCancelReasonCode,
  requestArrivalLogToExcel,
  postUserDefaultLocation,
  putTaskAssign,
  putTaskEnd,
  getUsersByLocation,
  postUserDefaultShift,
  putTaskStart,
  getSecondaryReferenceByRefID,
  clientResponseByTaskUuid,
} from "../../NetworkHelpers";
import {
  Alert,
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Tooltip,
} from "reactstrap";
import { ChangeLocationFormField } from "../../Dashboard/Main/components/CurrentLocation";
import { ChangeShiftFormField } from "../../Dashboard/Main/components/CurrentShift";

import MomentDisplay from "../../assets/components/MomentDisplay";
import { withRouter } from "react-router";
import {
  convertCtoF,
  endOfDay,
  endOfDayEST,
  getLocalStorageName,
  getTableSize,
  startOfDay,
  startOfDayEST
} from "../../common/utilities";

import * as PropTypes from "prop-types";
import TableDateRangePicker from "../../common/TableDateRangePicker";
import TableDateRangePickerGO from "../../common/TableDateRangePickerGo";
import { showInfo, showError } from "../../MainApp";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
import * as uuid from "uuid";
import { getTemp, isF, getFlooredFixed, getTempSetpoint } from "./Tasks";


class Dwell extends Component {
  latestLocation = {
    longitude: "",
    latitude: "",
  };



  tableColumns = [

    {
      accessor: "taskLocationName",
      Header: "Location Name",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.taskLocationName ? row.original.taskLocationName : "";
      },
    },
    {
      accessor: "locationCode",
      Header: "Location ID",
      default: true,
      filterable: true,
      sortable: true,
    },

    {
      accessor: "trailerName",
      Header: "Trailer Name",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.trailerName ? row.original.trailerName : "";
      },
    },
    {
      accessor: "trailerNumber",
      Header: "Trailer Number",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.trailerNumber ? row.original.trailerNumber.replace(/ /g, '').toUpperCase() : "";
      },
    },

    {
      accessor: "assetType",
      Header: "Trailer Type",
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "arrivalTime",
      Header: "Arrival Time",
      default: true,
      filterable: false,
      sortable: true,
      dataType: "date",
      operator: "GT",
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.arrivalTime) {
          return (
            <MomentDisplay
              date={task.arrivalTime}
              timezone={task.locationTimezone}

            />
          );
        } else {
          return null;
        }
      },
    },

    {
      accessor: "departTime",
      Header: "Departure Time",
      default: true,
      filterable: false,
      sortable: true,
      dataType: "date",
      operator: "GT",
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.departTime) {
          return (
            <MomentDisplay
              date={task.departTime}
              timezone={task.locationTimezone}

            />
          );
        } else {
          return null;
        }
      },
    },
    {
      accessor: "dwellTime",
      Header: "Dwell Time",
      default: true,
      filterable: false,
      sortable: true,
    },
    {
      accessor: "arrivalTaskType",
      Header: "Arrival Task Type",
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "assetStatus",
      Header: "Arrival Trailer Status",
      default: true,
      title: "Current asset status for the task",
      filterable: true,
      sortable: true,
    },
    {
      accessor: "shipmentNumber",
      Header: "Arrival Shipment Number",
      default: true,
      filterable: true,
      sortable: true,

    },
    {
      accessor: "secondaryRef",
      Header: "Arrival Reference Number ",
      default: false,
      show: false,

      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.secondaryRef ? row.original.secondaryRef : "";
      },
    },
    {
      accessor: "departTaskType",
      Header: " Departure Task Type",
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "departAssetStatus",
      Header: "Departure Trailer Status",
      default: true,
      title: "Current asset status for the task",
      filterable: true,
      sortable: true,
    },
    {
      accessor: "departShipmentNumber",
      Header: "Departure Shipment Number",
      default: true,
      filterable: true,
      sortable: true,

    },
    {
      accessor: "departSecondaryRef",
      Header: "Departure Reference Number ",
      default: false,
      show: false,

      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.departSecondaryRef ? row.original.departSecondaryRef : "";
      },
    },
    {
      accessor: "temperatureInc",
      Header: "Arrival Temp - Actual" + (isF(this.props.user) ? " (℉)" : " (℃)"),
      filterable: false,
      sortable: true,
      default: true,
      Cell: (row) => {
        return getTemp(row, null, this.props.user);
      },
    },
    {
      accessor: "temperatureSetPointInc",
      Header: "Arrival Temp - Setpoint " + (isF(this.props.user) ? " (℉)" : " (℃)"),
      filterable: false,
      sortable: true,
      default: true,
      Cell: (row) => {
        return getTempSetpoint(row, this.props.user);
      },
    },


    {
      accessor: "temperatureDeviationInc",
      Header: "Arrival Temp - Deviation " + (isF(this.props.user) ? " (℉)" : " (℃)"),
      filterable: false,
      default: true,
      sortable: true,
      Cell: (row) => {
        return getTemp(
          row,
          row.original.temperatureSetPointInc,
          this.props.user
        );
      },
    },
    {
      accessor: "fuelLevel",
      Header: "Arrival Fuel Level",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.fuelLevel ? row.original.fuelLevel : "";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.fuelFilterRef}
            onChange={(event) => {
              this.setState({ fuelFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.fuelFilter ? this.state.fuelFilter : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="Empty">Empty</option>
            <option value="1/8">1/8</option>
            <option value="1/4">1/4</option>
            <option value="3/8">3/8</option>
            <option value="1/2">1/2</option>
            <option value="5/8">5/8</option>
            <option value="3/4">3/4</option>
            <option value="7/8">7/8</option>
            <option value="Full">Full</option>
          </select>
        </>
      ),
    },
    {
      accessor: "reefer",
      Header: "Arrival Reefer Status (On/Off)",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.reefer == true ? "Yes" : row.original.reefer == false ? "No" : "";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.reeferFilterRef}
            onChange={(event) => {
              this.setState({ reeferFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.reeferFilter ? this.state.reeferFilter : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="YES">Yes</option>
            <option value="NO">No</option>
            <option value="EMPTY">Blank</option>

          </select>
        </>
      ),
    },
    {
      accessor: "continous",
      Header: "Arrival Reefer Continuous (True/False)",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.continous == true ? "Yes" : row.original.continous == false ? "No" : "";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.continousFilterRef}
            onChange={(event) => {
              this.setState({ continousFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.continousFilter ? this.state.continousFilter : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="YES">Yes</option>
            <option value="NO">No</option>
            <option value="EMPTY">Blank</option>

          </select>
        </>
      ),
    },

    {
      accessor: "departTemperatureInc",
      Header: "Departure Temp - Actual" + (isF(this.props.user) ? " (℉)" : " (℃)"),
      filterable: false,
      sortable: false,
      default: true,
      Cell: (row) => {
        return this.getDepartTemp(row, null, this.props.user);
      },
    },
    {
      accessor: "departTemperatureSetPointInc",
      Header: "Departure Temp - Setpoint" + (isF(this.props.user) ? " (℉)" : " (℃)"),
      filterable: false,
      sortable: false,
      default: true,
      Cell: (row) => {
        return this.getDepartTempSetpoint(row, this.props.user);
      },
    },


    {
      accessor: "departTemperatureDeviationInc",
      Header: "Departure Temp - Deviation" + (isF(this.props.user) ? " (℉)" : " (℃)"),
      filterable: false,
      default: true,
      sortable: false,
      Cell: (row) => {
        return this.getDepartTemp(
          row,
          row.original.departTemperatureSetPointInc,
          this.props.user
        );
      },
    },
    {
      accessor: "departFuelLevel",
      Header: "Departure Fuel Level",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.departFuelLevel ? row.original.departFuelLevel : "";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.dfuelFilterRef}
            onChange={(event) => {
              this.setState({ dfuelFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.dfuelFilter ? this.state.dfuelFilter : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="Empty">Empty</option>
            <option value="1/8">1/8</option>
            <option value="1/4">1/4</option>
            <option value="3/8">3/8</option>
            <option value="1/2">1/2</option>
            <option value="5/8">5/8</option>
            <option value="3/4">3/4</option>
            <option value="7/8">7/8</option>
            <option value="Full">Full</option>
          </select>
        </>
      ),
    },
    {
      accessor: "departReefer",
      Header: "Departure Reefer Status (On/Off)",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.departReefer == true ? "Yes" : row.original.departReefer == false ? "No" : "";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.dreeferFilterRef}
            onChange={(event) => {
              this.setState({ dreeferFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.dreeferFilter ? this.state.dreeferFilter : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="YES">Yes</option>
            <option value="NO">No</option>
            <option value="EMPTY">Blank</option>

          </select>
        </>
      ),
    },
    {
      accessor: "departContinous",
      Header: "Departure Reefer Continuous (True/False)",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.departContinous == true ? "Yes" : row.original.departContinous == false ? "No" : "";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.dcontinousFilterRef}
            onChange={(event) => {
              this.setState({ dcontinousFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.dcontinousFilter ? this.state.dcontinousFilter : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="YES">Yes</option>
            <option value="NO">No</option>
            <option value="EMPTY">Blank</option>

          </select>
        </>
      ),
    },

  ];
  getDepartTemp(row, setPoint, user) {
    let temperatureInC = row.original.departTemperatureInc;

    if (temperatureInC == null) {
      return "";
    }

    if (setPoint != null) {
      if (isF(user)) {
        let tempInF = convertCtoF(temperatureInC);
        let setPointInF = convertCtoF(setPoint);
        tempInF -= setPointInF;
        let a = tempInF;
        return getFlooredFixed(a, 1) + " ℉";
      } else {
        temperatureInC -= setPoint;
        let a = temperatureInC;
        return getFlooredFixed(a, 1) + " ℃";
      }
    }
    if (isF(user)) {
      let a = convertCtoF(temperatureInC);
      return getFlooredFixed(a, 1) + " ℉";
    } else {
      let a = temperatureInC;
      return getFlooredFixed(a, 1) + " ℃";
    }
  };
  getDepartTempSetpoint(row, user) {
    let tempSetPoint = row.original.departTemperatureSetPointInc;
    if (tempSetPoint == null) {
      return "";
    }
    if (isF(user)) {
      let a = convertCtoF(tempSetPoint);
      return getFlooredFixed(a, 1) + " ℉";
    } else {
      let a = tempSetPoint;
      return getFlooredFixed(a, 1) + " ℃";
    }
  };
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }

  constructor(props) {
    super(props);
    console.log("task props", props);
    this.tableRef = React.createRef();
    this.taskStatusFilterRef = React.createRef();
    this.reeferFilterRef = React.createRef();
    this.fuelFilter = React.createRef();
    this.continousFilterRef = React.createRef();
    this.dreeferFilterRef = React.createRef();
    this.dfuelFilter = React.createRef();
    this.dcontinousFilterRef = React.createRef();
    const parse = JSON.parse(
      localStorage.getItem(getLocalStorageName("poweryard-dwell", props.user)),
      this.mapFromJson
    );
    this.state = {
      hideFilters: true,
      forceUpdate: false,
      isdisabled: false,
      showTooltip: false,
      clickedRowIndex: 0,
      Showhover: true,
      istab: false,
      page: 0,
      pagesize: 0,
    };
    if (parse) {
      this.state = {
        name: null,
        previousSearchRequest: {
          sorted: [],
          filtered: [
            { id: "taskLocationUuid", value: this.props.currentLocation.uuid },
            { id: "start", value: startOfDay(new Date(), 7), operator: "GT" },
            { id: "end", value: endOfDay(new Date()), operator: "LT" },
            { id: "dateFilterField", value: "arrivalTime", operator: "EQ" },
          ],
        },
        filterStartDate: startOfDayEST(new Date()),
        filterEndDate: endOfDayEST(new Date()),
        filterDateType: "arrivalTime",
        taskStatusFilter: "undefined",
        reeferFilter: parse.reeferFilter,
        fuelFilter: parse.fuelFilter,
        continousFilter: parse.continousFilter,
        dreeferFilter: parse.dreeferFilter,
        dfuelFilter: parse.dfuelFilter,
        dcontinousFilter: parse.dcontinousFilter,
        dateFilterEnabled: true,
        tableSize: parse.tableSize,
        columnSizes: parse.columnSizes,
        autoRefreshEnabled: parse.autoRefreshEnabled,
        allLocations: [],
        showFilterCollaps: true,
        watcher: undefined,
        currentLocationObj: {},
        loaded: false,
        checkForFlag: true,
        cancelLoaded: false,
        gettingRef: null,
        isFalRefModalOpen: false,

        flagData: {
          respondingSystem: null,
          reasonCode: null,
          Description: null,
          Datetime: null,
        },

        tooltipOpen: false,
        id: "ID_" + uuid.v4(),
      };
    } else {
      this.state = {
        name: null,
        watcher: undefined,
        previousSearchRequest: {
          sorted: [{ id: "start", desc: "true" }],
          filtered: [
            { id: "taskLocationUuid", value: this.props.currentLocation.uuid },

            {
              id: "start",
              value: startOfDay(
                new Date(new Date().setDate(new Date().getDate() - 7))
              ),
              operator: "GT",
            },
            { id: "end", value: endOfDay(new Date()), operator: "LT" },
            { id: "dateFilterField", value: "arrivalTime", operator: "EQ" },
          ],
        },
        filterStartDate: startOfDayEST(new Date()),
        filterEndDate: endOfDayEST(new Date()),
        filterDateType: "arrivalTime",
        reeferFilter: "undefined",
        fuelFilter: "undefined",
        continousFilter: "undefined",
        dreeferFilter: "undefined",
        dfuelFilter: "undefined",
        dcontinousFilter: "undefined",
        showFilterCollaps: true,
        isFalRefModalOpen: false,
        tableSize: { height: "500px", width: "500px" },
        columnSizes: new Map(),
        dateFilterEnabled: true,
        allLocations: [],
        currentLocationObj: {},
        allUsers: [],
        autoRefreshEnabled: true,
        loaded: false,
        cancelLoaded: false,

        flagData: {
          system: null,
          reasonCode: null,
          description: null,
          responseDate: null,
          taskName: null,
        },
        tooltipOpen: false,
        id: "ID_" + uuid.v4(),
      };
    }
    if (!this.state.filterEndDate) {
      this.state.filterEndDate = endOfDay(new Date());
    }
    if (!this.state.filterStartDate) {
      this.state.filterStartDate = startOfDay(
        new Date(new Date().setDate(new Date().getDate() - 7))
      );
    }
    if (!this.state.filterDateType) {
      this.state.filterDateType = "arrivalTime";
    }
    getUsersByLocation(this.props.currentLocation.uuid)("").then((res) => {
      let userArr = res;
      userArr = userArr.filter((it) => it.enabled === true);
      userArr.forEach((element) => {
        element.assignee = element.firstname + " " + element.lastname;
        element.label = element.firstname + " " + element.lastname;
      });
      this.setState({ allUsers: userArr, usersCalled: true });
    });

    if (!this.state.tableSize) {
      this.state.tableSize = { height: "500px", width: "500px" };
    }
    console.log("Location", this.props.currentLocation);

    getDefaultCancelReasonCode(
      (res) => {
        let data = res.content;
        data.label = data.name;
        data.value = data.uuid;
        this.setState({
          defaultCancelReasonCode: data,
        });
      },
      () => {
        this.setState({
          defaultCancelReasonCode: null,
        });
      }
    );
    if (!this.state.columnSizes) {
      this.state.columnSizes = new Map();
    }
    this.tableColumns = this.tableColumns.map((col) => {
      if (this.state.columnSizes.has(col.accessor)) {
        col.width = this.state.columnSizes.get(col.accessor);
      }
      return col;
    });

  }


  filterName = (inputValue) => {
    let listData = [];
    if (
      this.state.allUsers &&
      this.state.allUsers.length !== 0 &&
      this.state.usersCalled === true
    ) {
      let userArr = this.state.allUsers;
      // userArr = userArr.filter(it=> it.enabled === true);
      // userArr.forEach(element => {

      //     if (inputValue === "" || inputValue === null || inputValue === undefined) {
      //         if (element.locations.length > 0) {
      //             let userLocation = element.locations.filter(it => it.uuid === this.props.currentLocation.uuid);
      //             if (userLocation.length > 0) {
      //                 listData.push(element);
      //             }
      //         }
      //     } else {
      //         if (element.locations.length === 0) {
      //             listData.push(element);
      //         }
      //         if (element.locations.length > 0) {
      //             let userLocation = element.locations.filter(it => it.uuid === this.props.currentLocation.uuid);
      //             if (userLocation.length > 0) {
      //                 listData.push(element);
      //             }
      //         }
      //     }
      // });
      // if (inputValue !== "" && inputValue !== undefined) {
      //     getUsersByLocation(this.props.currentLocation.uuid)(inputValue).then((res)=>{
      //         let uArr = res;
      //         uArr = uArr.filter(it=> it.enabled === true);
      //         uArr.forEach(element => {
      //                 element.assignee = element.firstname + " " + element.lastname;
      //                 element.label = element.firstname + " " + element.lastname;

      //             });
      //             this.setState({ allUsers: uArr,usersCalled : true });
      //     }).then({listData = uArr});
      // } else {
      // }
      listData = userArr;
      return listData;
    } else {
      // let searchRequest = { "page": 0, "pageSize": 100, "sorted": [], "filtered": [] };
      getUsersByLocation(this.props.currentLocation.uuid)(inputValue).then(
        (res) => {
          let userArr = res;
          userArr = userArr.filter((it) => it.enabled === true);
          userArr.forEach((element) => {
            element.assignee = element.firstname + " " + element.lastname;
            element.label = element.firstname + " " + element.lastname;
          });
          this.setState({ allUsers: userArr, usersCalled: true });
        }
      );
    }
  };
  // Due to performance issue created by this call this code is revereted (PAGE NEEDS TO BE REFRESHED TO GET DISABLED USER LIST))
  // checkInput(){
  //     let searchRequest = { "page": 0, "pageSize": 100, "sorted": [], "filtered": [] };
  //         promiseUserByLocation(searchRequest,
  //             (response) => {

  //                 let userArr = response.content;

  //                 userArr = userArr.filter(it=> it.enabled === true);
  //                 userArr.forEach(element => {
  //                     element.assignee = element.firstname + " " + element.lastname;
  //                     element.label = element.firstname + " " + element.lastname;

  //                 });
  //                 this.setState({ allUsers: userArr });

  //             });
  // }
  loadOptions = (inputValue, callback) => {
    // if(inputValue !== ""){
    //     this.checkInput();
    //     setTimeout(() => {
    //         callback(this.filterName(inputValue));
    //     }, 3000);

    // } else {
    if (inputValue !== "" && inputValue !== null && inputValue !== undefined) {
      getUsersByLocation(this.props.currentLocation.uuid)(inputValue).then(
        (res) => {
          let userArr = res;
          userArr = userArr.filter((it) => it.enabled === true);
          userArr.forEach((element) => {
            element.assignee = element.firstname + " " + element.lastname;
            element.label = element.firstname + " " + element.lastname;
          });
          this.setState({ allUsers: userArr, usersCalled: true });
          callback(this.filterName(inputValue));
        }
      );
    } else {
      setTimeout(() => {
        callback(this.filterName(inputValue));
      }, 3000);
    }

    // }
  };
  triggerUpdate = () => {
    this.setState({ forceUpdate: !this.state.forceUpdate });
  };
  checkTimeAndReload() {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();

    // Check if it is 12:00 AM
    if (hours === 0 && minutes === 0 && seconds === 0) {
      // Reload the page
      window.location.reload();
    }
  }
  componentDidMount() {

    window.scrollTo(0, 0);
    window.addEventListener("resize", this.calculateTableSize.bind(this));
    this.calculateTableSize();

    this.timerId = setInterval(() => {
      this.checkTimeAndReload();
    }, 1000);

    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent
      );
    // console.log(isTablet)
    if (isTablet === true) {
      document.documentElement.requestFullscreen();
      const datePickers = document.getElementsByClassName(
        "react-datepicker__input-container"
      );
      Array.from(datePickers).forEach((el) =>
        el.childNodes[0].setAttribute("readOnly", true)
      );

      this.setState({ istab: true });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log('Current Time Zone:', this.props.currentLocation);


  }



  componentWillUnmount() {
    window.removeEventListener("resize", this.calculateTableSize.bind(this));
    window.scrollTo(0, 0);
    // navigator.geolocation.clearWatch(this.state.watcher);

    clearInterval(this.timerId);

    this.savePageState();
  }
  mapToJson(key, value) {
    let objectToSerialize = this[key];
    if (objectToSerialize instanceof Map) {
      return {
        dataType: "Map",
        values: Array.from(objectToSerialize.entries()),
      };
    } else {
      return value;
    }
  }
  mapFromJson(key, value) {
    if (typeof value === "object" && value !== null) {
      if (value.dataType === "Map") {
        return new Map(value.values);
      }
    }
    return value;
  }
  savePageState() {
    localStorage.setItem(
      getLocalStorageName("poweryard-dwell", this.props.user),
      JSON.stringify(
        {
          previousSearchRequest: this.state.previousSearchRequest,
          showFilterCollaps: this.state.showFilterCollaps,
          filterStartDate: this.state.filterStartDate,
          filterEndDate: this.state.filterEndDate,
          filterDateType: this.state.filterDateType,
          dateFilterEnabled: this.state.dateFilterEnabled,
          reeferFilter: this.state.reeferFilter,
          fuelFilter: this.state.fuelFilter,
          continousFilter: this.state.continousFilter,
          dreeferFilter: this.state.dreeferFilter,
          dfuelFilter: this.state.dfuelFilter,
          dcontinousFilter: this.state.dcontinousFilter,
          columnSizes: this.state.columnSizes,
          autoRefreshEnabled: this.state.autoRefreshEnabled,
        },
        this.mapToJson
      )
    );
  }
  calculateTableSize = () => {
    let size = getTableSize("power-yard-task-page", "power-yard-tasks-header");
    this.setState({ tableSize: { height: size.height, width: size.width } });
  };
  exportArrivalLog() {
    this.setState({ isdisabled: true, loaded: true });
    this.updateSpinner("Exporting...");
    if (this.state.previousSearchRequest) {

      requestArrivalLogToExcel(
        this.state.previousSearchRequest,
        "assets-dwell-export.xlsx",
        (response) => {
          this.setState({ loaded: false });

          if (response.emailSent) {
            showInfo(
              "We are currently generating the Excel file and you will receive it via email shortly."
            );
          }

        },
        (e) => {
          console.log(e);
          showError(
            "We are not able to generate the excel file at this time.  Please try again later."
          );
        }
      );
    }
  }

  render() {
    return (
      <>


        <Loader show={this.state.loaded} message={this.spinner}>
          <div id="power-yard-task-page">

            <div className="column-wrapper text-left">
              <div className="page-header" id="power-yard-tasks-header">
                <Row>
                  <Col>
                    <h1>
                      Dwell History &nbsp;{" "}


                    </h1>
                  </Col>
                  {this.props.currentLocation.enableArrivalTracking && (
                    <>
                      <Col className={"text-left"}>
                        <ChangeShiftFormField
                          yardCheckActive={this.props.user.onYardCheck}
                          onChange={(shift, location) => {
                            if (shift) {
                              if (this.props.currentShift !== shift) {
                                this.props.setCurrentShift(shift);
                                if (shift) {
                                  let data = this.props.user;
                                  data.shift = shift.value;
                                  data.version = data.version + 1;
                                  data.password = "NOPASSWORD";
                                  postUserDefaultShift(data);
                                }
                              }
                            }
                            if (location) {
                              if (this.props.currentLocation.uuid !== location.uuid) {
                                this.props.setCurrentLocation(location);
                                if (location && location.uuid) {
                                  postUserDefaultLocation(location.uuid);
                                }
                              }
                            }
                          }}
                          currentShift={this.props.currentShift}
                          currentLocation={this.props.currentLocation}
                        />
                      </Col>
                      <Col className={"text-right"}>
                        <ChangeLocationFormField
                          yardCheckActive={this.props.user.onYardCheck}
                          onChange={(location) => {
                            if (this.props.currentLocation.uuid !== location.uuid) {
                              this.props.setCurrentLocation(location);
                              if (location && location.uuid) {
                                postUserDefaultLocation(
                                  location.uuid,
                                  (success) => {
                                    window.location.reload();
                                    this.tableRef.current.requestPageSearch();
                                  },
                                  (err) => { }
                                );
                              }
                            }
                          }}
                          currentLocation={this.props.currentLocation}
                        />
                      </Col>
                    </>
                  )}

                </Row>
              </div>

              <PaginationFilterTable
                onTaskHistory={true}

                setloaded={(check) => {
                  this.updateSpinner("");
                  this.setState({ loaded: check });
                  this.updateSpinner("");
                }}
                openFiltersCollaps={this.state.showFilterCollaps}
                showEditColumns
                autoRefreshEnabled={this.state.autoRefreshEnabled}
                autoRefreshInterval={this.roleBasedInterval()}
                user={this.props.user}
                style={{
                  maxHeight: this.state.tableSize.height,
                  maxWidth: this.state.tableSize.width,
                  minHeight: "120px",
                }}
                ref={this.tableRef}
                get={searchAseetDwell}

                successCallback={(response) => {
                  this.setState({
                    hasResults: response.totalElements > 0,
                    inTask: response.inTask,
                    fetched: true,
                    pagesize: response.objectsPerPage,
                    page: response.pageNumber,
                  });

                }}
                baseFilter={[

                ]}
                filtered={this.state.previousSearchRequest.filtered}
                onFilteredChange={(filter) => {
                  const delayFilters = ['taskStatus', 'priority', 'boxedIn', 'sealed', 'reefer', 'continous'];
                  let psr = { ...this.state.previousSearchRequest };
                  psr.filtered = { ...this.state.filtered };
                  psr.filtered = filter;
                 const hasDelayFilter = psr.filtered.some((filter, index, array) => {
                    if (array.length === 1) {
                      return delayFilters.includes(filter.id);
                    } else if (array.length > 1) {
                      return index === array.length - 1 && delayFilters.includes(filter.id);
                    }
                    return false;
                  });
                  this.setState({ previousSearchRequest: psr }, () => {
                    if (hasDelayFilter) {
                      this.tableRef.current.requestPageSearch();
                    } else {

                      setTimeout(() => {
                        this.tableRef.current.requestPageSearch();
                      }, 3000); // 4000 milliseconds = 4 seconds



                    }
                  });

                }}
                clearFilter={(resetAll) => {
                  if (resetAll) {
                    console.log("reset all", this.state.previousSearchRequest);
                    let psr = { ...this.state.previousSearchRequest };
                    psr.filtered = [];
                    this.setState({
                      filterStartDate: startOfDayEST(new Date()),
                      filterEndDate: endOfDayEST(new Date()),
                      dateFilterEnabled: true,
                      previousSearchRequest: psr,
                    },
                      () => {
                        // this.tableRef.current.restoreColumns(true);
                        this.updateSpinner("");
                        this.tableRef.current.setState({ page: 0, forcePage: 0 });
                        if (this.props.currentLocation.enableArrivalTracking) {
                          this.updateProperty("taskLocationUuid", this.props.currentLocation.uuid);
                          this.updateProperty("start", this.state.filterStartDate);
                          this.updateProperty("start", this.state.filterStartDate);
                          this.updateProperty("end", this.state.filterEndDate);
                          this.updateProperty("dateFilterField", this.state.filterDateType);
                        }
                        else {
                          this.updateProperty("start", this.state.filterStartDate);
                          this.updateProperty("start", this.state.filterStartDate);
                          this.updateProperty("end", this.state.filterEndDate);
                          this.updateProperty("dateFilterField", this.state.filterDateType);
                        }

                      }
                    );
                  } else {
                    let psr = { ...this.state.previousSearchRequest };
                    psr.filtered = psr.filtered.filter(
                      (f) =>
                      
                        f.id === "taskLocationUuid" ||
                        f.id === "start" ||
                        f.id === "end" ||
                        f.id === "dateFilterField"
                    );
                    this.setState(
                      {
                        previousSearchRequest: psr,
                      },
                      () => {

                        if (this.state.hideFilters == false) {
                          this.updateSpinner("");
                          this.tableRef.current.requestPageSearch()
                        }
                      }
                    );
                  }
                }}
                dateFilterEnabled={this.state.dateFilterEnabled}
                defaultSorted={[]}
                stateStorage={"poweryard-dwell"}
                columns={this.tableColumns}
                setFilterToogle={(value) => {
                  this.setState({
                    hideFilters: value,

                  });
                }}
                onFilterHide={() => {
                  this.setState({
                    taskStatusFilter: "undefined",
                    enabledFilter: "undefined",
                    reeferFilter: "undefined",
                    fuelFilter: "undefined",
                    continousFilter: "undefined",
                    dreeferFilter: "undefined",
                    dfuelFilter: "undefined",
                    dcontinousFilter: "undefined",

                  });
                }}
                onChange={(state) => {
                  this.setState({
                    previousSearchRequest: {
                      dateFilter: state.dateFilter,
                      itemsPerPage: state.itemsPerPage,
                      page: state.page,
                      pageSize: state.pageSize,
                      sorted: state.sorted,
                      filtered: state.filtered,
                    },
                  });
                }}
                onResizedChange={(customizedColumns, event) => {
                  let columnSizes = this.state.columnSizes
                    ? this.state.columnSizes
                    : new Map();
                  customizedColumns.forEach((col) => {
                    columnSizes.set(col.id, col.value);
                  });
                  this.setState({ columnSizes: columnSizes });
                }}
                footerButton={
                  <Button
                    disabled={!this.state.hasResults}

                    color="secondary"
                    onClick={() => {
                      this.exportArrivalLog();
                    }}
                  >
                    <i className="fa fa-file-excel" /> Export to Excel
                  </Button>
                }
              >
                <TableDateRangePickerGO
                  showTaskSelector={true}
                  start={this.state.filterStartDate}
                  end={this.state.filterEndDate}
                  filterEnabled={this.state.dateFilterEnabled}
                  dateType={this.state.filterDateType}
                  hisotryPageType={"DwellPage"}

                  onDateFilterEnable={(enabled) => {
                    this.setState({ dateFilterEnabled: enabled });
                    if (enabled) {
                      this.updateProperty("start", this.state.filterStartDate);
                      this.updateProperty("end", this.state.filterEndDate);
                      this.updateProperty(
                        "dateFilterField",
                        this.state.filterDateType
                      );
                    } else {
                      this.removeFilter("start");
                      this.removeFilter("end");
                      this.removeFilter("dateFilterField");
                    }
                    //this.tableRef.current.requestPageSearch();
                  }}
                  DateChangeApiCall={(date) => {
                    this.updateSpinner("Loading...");
                    this.tableRef.current.requestPageSearch();

                  }}
                  onStartDateChange={(date) => {
                    this.updateProperty("start", date);
                    this.setState({ filterStartDate: date });
                  }}
                  onEndDateChange={(date) => {
                    this.updateProperty("end", date);
                    this.setState({ filterEndDate: date });
                  }}
                  onDateTypeChange={(val) => {
                    this.updateProperty("dateFilterField", val);
                    this.setState({ filterDateType: val });
                  }}
                />
              </PaginationFilterTable>
            </div>
          </div>
        </Loader>
      </>
    );
  }

  removeFilter(id) {
    let previousSearchRequest = Object.assign(
      {},
      this.state.previousSearchRequest
    );
    let filtered = previousSearchRequest.filtered;
    if (filtered == null) {
      filtered = [];
    }
    for (let i = 0; i < filtered.length; i++) {
      if (filtered[i].id === id) {
        filtered.splice(i, 1);
        break;
      }
    }
    previousSearchRequest.filtered = filtered;
    this.setState({ previousSearchRequest: previousSearchRequest }, () => {
      this.savePageState();
    });
  }
  updateProperty(id, value) {
    let previousSearchRequest = Object.assign(
      {},
      this.state.previousSearchRequest
    );
    let filtered = previousSearchRequest.filtered;
    let found = false;
    if (filtered == null) {
      filtered = [];
    }
    for (const filter of filtered) {
      if (filter.id === id) {
        filter.value = value;
        found = true;
      }
    }

    if (!found) {
      if (id === "start") {
        filtered.push({ id: id, value: value, operator: "GT" });
      } else if (id === "end") {
        filtered.push({ id: id, value: value, operator: "LT" });
      } else {
        filtered.push({ id: id, value: value, operator: "EQ" });
      }
    }
    previousSearchRequest.filtered = filtered;
    this.setState(
      {
        previousSearchRequest: previousSearchRequest,
      },
      () => {
        this.savePageState();
      }
    );
  }
  roleBasedInterval() {
    let s = this.props.user;
    let index = s.roles.findIndex((it) => it.role === "ROLE_POWERYARD_JOCKEY");
    if (index > -1) {
      return 30000;
    } else {
      return 120000;
    }
  }
  getProperty(field) {
    let previousSearchRequest = Object.assign(
      {},
      this.state.previousSearchRequest
    );
    let filtered = previousSearchRequest.filtered;
    if (filtered) {
      for (const filter of filtered) {
        if (filter && field) {
          if (filter.id === field) {
            return filter.value;
          }
        }
      }
    }
  }
}
Dwell.propTypes = {
  currentLocation: PropTypes.object,
  currentShift: PropTypes.string,
  position: PropTypes.object,
  user: PropTypes.object,
  setCurrentLocation: PropTypes.func,
  setCurrentShift: PropTypes.func,
};

export default withRouter(Dwell);