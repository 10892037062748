export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_ACCOUNTS_PAYABLE = "ROLE_ACCOUNTS_PAYABLE";
export const ROLE_E4SCORE_ADMIN = "ROLE_E4SCORE_ADMIN";
export const ROLE_ATTENDANT = "ROLE_ATTENDANT";
export const ROLE_OPERATOR = "ROLE_OPERATOR";
export const ROLE_CARRIER_ADMIN = "ROLE_CARRIER_ADMIN";
export const ROLE_CARRIER_ACCOUNTS_PAYABLE = "ROLE_CARRIER_ACCOUNTS_PAYABLE";
export const ROLE_CARRIER_DISPATCHER = "ROLE_CARRIER_DISPATCHER";
export const ROLE_POWERYARD_ADMIN = "ROLE_POWERYARD_ADMIN";
export const ROLE_POWERYARD_MANAGER = "ROLE_POWERYARD_MANAGER";
export const ROLE_POWERYARD_JOCKEY = "ROLE_POWERYARD_JOCKEY";
export const ROLE_POWERYARD_REQUESTER = "ROLE_POWERYARD_REQUESTER";
export const ROLE_TRACKASSURED_ADMIN = "ROLE_TRACKASSURED_ADMIN";
export const ROLE_TRACKASSURED_USER = "ROLE_TRACKASSURED_USER";
export const ROLE_EZTRACK_ADMIN = "ROLE_EZTRACK_ADMIN";
export const ROLE_EZTRACK_MANAGER = "ROLE_EZTRACK_MANAGER";
export const ROLE_EZTRACK_USER = "ROLE_EZTRACK_USER";
export const ROLE_GATE_USER = "ROLE_GATE_USER";

export function userHasRole(user, roles) {
  let ret = false;
  if (user) {
    for (let i = 0; i < user.roles.length; i++) {
      if (roles.indexOf(user.roles[i].role) >= 0) {
        ret = true;
        break;
      }
    }
  }
  return ret;
}
export function isLocationRequired(roles) {
  if (roles.length === 1 && roles[0].role === "ROLE_POWERYARD_JOCKEY") {
    return true;
  }

  if (
    roles.length === 2 &&
    [
      ["ROLE_POWERYARD_JOCKEY", "ROLE_GATE_USER"],
      ["ROLE_POWERYARD_JOCKEY", "ROLE_POWERYARD_MANAGER"],
      ["ROLE_POWERYARD_JOCKEY", "ROLE_POWERYARD_REQUESTER"],
    ].some((pair) =>
      pair.every((role) => roles.some((r) => r.role === role))
    )
  ) {
    return true;
  }

  if (
    roles.length === 3 &&
    ["ROLE_POWERYARD_JOCKEY", "ROLE_POWERYARD_MANAGER", "ROLE_GATE_USER"].every((role) =>
      roles.some((r) => r.role === role)
    )
  ) {
    return true;
  }


  return false;
}

