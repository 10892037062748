import React, { Component } from "react";
import { PaginationFilterTable } from "../../common/PaginationFilterTable";
import {
  downloadTasks,
  getDefaultCancelReasonCode,
  postUserDefaultLocation,
  promiseCancelReasonSearch,
  putTaskAssign,
  putTaskCancel,
  putTaskEnd,
  assignTask,
  saveTask,
  putTaskRemoveAssignee,
  getUsersByLocation,
  postUserDefaultShift,
  putTaskStart,
  promiseAllLocation,
  promiseUserLocationSearch,
  promiseUserByLocationEdit,
  getReferenceTypes,
  downloadCurrentStep,
  getSecondaryReferenceByRefID,
  getTaskReferenceByRefID,
  clientResponseByTaskUuid,
  getRolesByLocation,
} from "../../NetworkHelpers";
import {
  Alert,
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Tooltip,
} from "reactstrap";
import { ChangeLocationFormField } from "../../Dashboard/Main/components/CurrentLocation";
import { ChangeShiftFormField } from "../../Dashboard/Main/components/CurrentShift";

import MomentDisplay from "../../assets/components/MomentDisplay";
import { withRouter } from "react-router";
import { getFormattedDwellDateString } from "../../assets/components/StaticDurationDisplay";
import {
  convertCtoF,
  endOfDay,
  endOfDayEST,
  getLocalStorageName,
  getTableSize,
  startOfDay,
  startOfDayEST
} from "../../common/utilities";

import AsyncSelect from "react-select/lib/Async";
import * as PropTypes from "prop-types";
import TableDateRangePicker from "../../common/TableDateRangePicker";
import { showInfo, showError } from "../../MainApp";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
import ReferenceModal from "./ReferenceModal";
import * as uuid from "uuid";
import IconWithTooltip from "../../common/IconWithTooltip";
import FlagModal from "../../common/FlagModal";
import CancelTaskModal from "../../common/CancelTaskModal";

export const getTempSetpoint = function (row, user) {
  let tempSetPoint = row.original.temperatureSetPointInc;
  if (tempSetPoint == null) {
    return "";
  }
  if (isF(user)) {
    let a = convertCtoF(tempSetPoint);
    return getFlooredFixed(a, 1) + " ℉";
  } else {
    let a = tempSetPoint;
    return getFlooredFixed(a, 1) + " ℃";
  }
};
export const getTemp = function (row, setPoint, user) {
  let temperatureInC = row.original.temperatureInc;

  if (temperatureInC == null) {
    return "";
  }

  if (setPoint != null) {
    if (isF(user)) {
      let tempInF = convertCtoF(temperatureInC);
      let setPointInF = convertCtoF(setPoint);
      tempInF -= setPointInF;
      let a = tempInF;
      return getFlooredFixed(a, 1) + " ℉";
    } else {
      temperatureInC -= setPoint;
      let a = temperatureInC;
      return getFlooredFixed(a, 1) + " ℃";
    }
  }
  if (isF(user)) {
    let a = convertCtoF(temperatureInC);
    return getFlooredFixed(a, 1) + " ℉";
  } else {
    let a = temperatureInC;
    return getFlooredFixed(a, 1) + " ℃";
  }
};
export const getFlooredFixed = function (v, d) {
  return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
};
export const isF = function (user) {
  return (
    user &&
    user.company &&
    user.company.temperatureUnitsOfMeasure &&
    user.company.temperatureUnitsOfMeasure === "Fahrenheit"
  );
};

const allUsers = [];
class Tasks extends Component {
  latestLocation = {
    longitude: "",
    latitude: "",
  };

  handleClickComments = (rowindex) => {
    if (this.state.istab === true) {
      this.setState({ Showhover: true });
      if (this.state.clickedRowIndex === rowindex) {
        this.setState((prevState) => ({
          showTooltip: !prevState.showTooltip,
          clickedRowIndex: rowindex,
        }));
      } else {
        this.setState({
          showTooltip: true,
          clickedRowIndex: rowindex,
        });
      }
    }

    console.log("tooltip", this.state.showTooltip);
  };

  openRefModal(ref, elem) {
    this.setState({ loaded: true });
    this.updateSpinner("Loading...");
    ref.clickedRef = `${elem.split(" ")[0]}  (${elem.split(" ")[1]})`;
    this.onGetRefDataByRefID(ref, elem.split(" ")[0]);
  }
  onGetRefDataByRefID(ref, elem) {
    getSecondaryReferenceByRefID(
      elem.trim(),
      (success) => {
        if (success.code === "200") {
          ref.refData = success.content;
          this.setState({
            isRefModalOpen: true,
            selectedTask: ref,
            loaded: false,
          });
        } else if (success.code === "1000") {
          showError(success.message);
          this.setState({ loaded: false });
        }
      },
      (error) => {
        this.setState({ loaded: false });
        showError(error.message);
        console.log(error);
      }
    );
  }

  tableColumns = [
    {
      Header: "Action",
      accessor: "action",
      width: 205,
      filterable: false,
      default: true,
      sortable: false,
      disableControls: true,
      resizable: false,

      Cell: (row) => {
        console.log("abc", row.original);
        return (
          <>
            <Button
              disabled={
                // row.original.taskStatus == "IsAssinged" ||
                row.original.taskStatus == "InProgress" || (this.props.currentLocation.enableArrivalTracking &&  row.original.taskStatus == "Complete" && row.original.taskMoved)

              }
              title="Edit"
              aria-label="Edit"
              onClick={() =>
                this.props.history.push("/task/" + row.original.taskUuid)
              }
            >

              <i className={`fa fa-edit ${row.original.abnormalTask === true ? "text-danger" : ""}`} />
            </Button>{" "}
            &nbsp;
            {this.notCancelled(row) && this.noEnd(row) && (
              <>
                <Button
                  disabled={
                    row.original.taskStatus == "InProgress"
                  }
                  title="Cancel"
                  aria-label="Cancel"
                  className={"btn-danger"}
                  onClick={() => {
                    this.setState({
                      cancelTask: row.original,
                      cancelModalOpen: true,
                    });
                  }}
                >
                  <i className="fa fa-times" />
                </Button>
              </>
            )}
            &nbsp;&nbsp;
            {/* Accept button logic */}
            {!this.state.inTask &&
              row.original.taskEndDate == null &&
              row.original.taskStatus !== "Canceled" &&
              row.original.taskStatus !== "Complete" &&
              row.original.taskStatus === "IsAssinged" &&
              row.original.locationEnableTaskAssignment === true &&
              row.original.assigneeUuid === this.props.user.uuid && (
                <>
                  {row.original.assigneeUuid === this.props.user.uuid && (
                    <>
                      <Button
                        onClick={() =>
                          this.getLocationFireEvent(
                            "Accepting",
                            row.original,
                            "accept",
                            this.props.user
                          )
                        }
                        color="primary"
                      >
                        Accept
                      </Button>
                    </>
                  )}
                </>
              )}
            {!this.state.inTask &&
              row.original.taskEndDate == null &&
              row.original.taskStatus !== "Canceled" &&
              row.original.taskStatus !== "Complete" &&
              row.original.locationEnableTaskAssignment === false &&
              row.original.taskStatus !== "InProgress" && (
                <>
                  {row.original.assigneeUuid &&
                    row.original.assigneeUuid === this.props.user.uuid && (
                      <>
                        <Button
                          onClick={() =>
                            this.getLocationFireEvent(
                              "Accepting",
                              row.original,
                              "accept",
                              this.props.user
                            )
                          }
                          color="primary"
                        >
                          Accept
                        </Button>
                      </>
                    )}
                </>
              )}
            {!this.state.inTask &&
              row.original.taskEndDate == null &&
              row.original.taskStatus !== "Canceled" &&
              row.original.taskStatus !== "Complete" &&
              row.original.locationEnableTaskAssignment === false &&
              row.original.taskStatus == "Pending" && (
                <>
                  <Button
                    onClick={() =>
                      this.getLocationFireEvent(
                        "Accepting",
                        row.original,
                        "accept",
                        this.props.user
                      )
                    }
                    color="primary"
                  >
                    Accept
                  </Button>
                </>
              )}
            &nbsp;
            {this.props.user &&
              row.original.assigneeUuid &&
              row.original.assigneeUuid === this.props.user.uuid &&
              row.original.taskStatus !== "Canceled" &&
              row.original.taskStatus !== "Complete" &&
              row.original.taskStartDate === null &&
              row.original.taskStatus !== "IsAssinged" && (
                <>
                  <Button
                    onClick={() =>
                      this.getLocationFireEvent(
                        "Starting",
                        row.original,
                        "start",
                        this.props.user
                      )
                    }
                    color="primary"
                  >
                    {" "}
                    <i className="fa fa-clock" /> Begin
                  </Button>
                </>
              )}
            &nbsp;
            {row.original.taskStartDate &&
              !row.original.taskEndDate &&
              !row.original.canceled &&
              row.original.assigneeUuid &&
              row.original.assigneeUuid === this.props.user.uuid && (
                <>
                  <Button
                    color="primary"
                    onClick={() =>
                      this.getLocationFireEvent(
                        "Ending",
                        row.original,
                        "end",
                        this.props.user
                      )
                    }
                  >
                    {" "}
                    <i className="fa fa-clock" /> End
                  </Button>
                </>
              )}
          </>
        );
      },
    },
    {
      accessor: "taskType",
      Header: "Task Type",
      default: true,
      filterable: true,
      sortable: true,
    },

    {
      accessor: "taskStatus",
      Header: "Task Status",
      default: true,
      title: "Task Status",
      filterable: true,
      sortable: true,
      Cell: (row) => {
        let cancelCode = null;
        if (row.original.taskStatus === "IsAssinged") {
          return <>Assigned</>;
        } else {
          if (row.original.taskStatus === "Canceled") {
            if (row.original.cancelReasonName) {
              cancelCode = " (" + row.original.cancelReasonName + ")";
            }
          }
          return (
            <>
              {row.original.taskStatus} {cancelCode}
            </>
          );
        }
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.taskStatusFilterRef}
            onChange={(event) => {
              this.setState({ taskStatusFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.taskStatusFilter
                ? this.state.taskStatusFilter
                : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="Pending">Pending</option>
            <option value="IsAssinged">Assigned</option>
            <option value="InProgress">In Progress</option>
            <option value="Complete">Complete</option>
            <option value="Canceled">Canceled</option>
          </select>
        </>
      ),
    },
    {
      accessor: "createdBy",
      Header: "Task Created By User",
      default: false,
      show: false,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "assigneeName",
      Header: "Assignee",
      width: 205,
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        let unassignButtonColor = row.original.assigneeUuid
          ? row.original.assigneeUuid === this.props.user.uuid
            ? "danger"
            : "secondary"
          : "secondary";
        if (row.original.assigneeName) {
          if (
            !row.original.taskEndDate &&
            row.original.taskStatus !== "Canceled"
          ) {
            return (
              <Button
                onClick={() => {
                  this.setState({ loaded: true });
                  this.updateSpinner("Removing Assignee");
                  putTaskRemoveAssignee(
                    task.taskUuid,
                    task.taskVersion,
                    this.props.position,
                    () => {
                      this.setState({ loaded: false });
                      this.tableRef.current.fetchData();
                      showInfo(
                        "The task has been unassigned and any start time cleared."
                      );
                    }
                  );
                }}
                style={{ minWidth: "100%" }}
                color={unassignButtonColor}
              >
                {row.original.assigneeName} <i className="fa fa-times-circle" />
              </Button>
            );
          } else {
            return <>{row.original.assigneeName} </>;
          }
        } else if (
          !row.original.taskEndDate &&
          row.original.taskStatus !== "Canceled"
        ) {
          if (this.props.user.roles[0].role !== "ROLE_POWERYARD_JOCKEY") {
            return (
              <AsyncSelect
                placeholder="Assign User"
                id={"select" + row.original.taskUuid}
                value={
                  this.state[row.original.taskUuid]
                    ? this.state[row.original.taskUuid]
                    : ""
                }
                className="showDropdown"
                isClearable
                defaultOptions
                loadOptions={this.loadOptions}
                onChange={(v) => {
                  // putTaskAsignee(task, () => {
                  //     this.tableRef.current.fetchData();
                  //     showInfo("User is assigned to task.");
                  // })
                  this.setState({
                    [row.original.taskUuid]: v.assignee,
                    loaded: true,
                  });
                  let coordinates = { latitude: "", longitude: "" };
                  this.updateSpinner("Assigning ...");

                  assignTask(
                    {
                      taskUuid: task.taskUuid,
                      userUuid: v.uuid,
                      taskVersion: task.taskVersion,
                    },
                    (res) => {
                      if (res.code == "1000") {
                        showInfo(res.message);
                      } else {
                        this.tableRef.current.fetchData();
                        this.setState({
                          [row.original.taskUuid]: "",
                          loaded: false,
                        });
                        showInfo("User is assigned to task.");
                      }
                    }
                  );

                }}
              />
            );
          } else {
            // let locationobj = this.state.allLocations.find(it => it.uuid === this.props.currentLocation.uuid);
            // if (locationobj) {

            //     if (locationobj.enableTaskAssigment) {
            //         let a = JSON.stringify(task);
            //         let taskObj = JSON.parse(a);
            //         taskObj.assignee = this.props.user;
            //         return <Button color="primary" onClick={() => saveTask(taskObj)} className={"w-100"} > <i className="fa fa-portrait" /> Assign Self</Button>;
            //     } else {
            //         return <div />;
            //     }
            // } else {
            //     return <div />;
            // }
            if (this.props.currentLocation.enableTaskAssigment) {
              // let a = JSON.stringify(task);
              // let taskObj = JSON.parse(a);
              // taskObj.assignee = this.props.user;
              return (
                <Button
                  color="primary"
                  onClick={() => {
                    this.updateSpinner("Assigning ...");

                    assignTask(
                      {
                        taskUuid: task.taskUuid,
                        userUuid: this.props.user.uuid,
                        taskVersion: task.taskVersion,
                      },
                      (res) => {
                        this.setState({ loaded: false });
                        if (res.code == "1000") {
                          showInfo(res.message);
                        } else {
                          this.tableRef.current.fetchData();
                          this.setState({
                            [row.original.taskUuid]: "",
                            loaded: false,
                          });
                          showInfo("User is assigned to task.");
                        }
                      }
                    );
                  }}
                  className={"w-100"}
                >
                  {" "}
                  <i className="fa fa-portrait" /> Assign Self
                </Button>
              );
            } else {
              return <div />;
            }
          }
        } else {
          return <div />;
        }
      },
    },
    {
      accessor: "lastModifiedBy",
      Header: "Last Updated By User",
      default: false,
      show: false,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "canceledBy",
      Header: "Canceled by User",
      default: false,
      show: false,
      filterable: false,
      sortable: false,
    },
    {
      accessor: "createdDate",
      Header: "Task Created Datetime",
      default: true,
      filterable: false,
      sortable: true,
      dataType: "date",
      operator: "GT",
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.createdDate) {
          return (
            <MomentDisplay
              date={task.createdDate}
              timezone={
                this.props.currentLocation &&
                this.props.currentLocation.timezone
              }
            />
          );
        } else {
          return null;
        }
      },
    },
    {
      accessor: "assignDate",
      Header: "Assigned Datetime",
      default: false,
      show: false,
      filterable: false,
      sortable: true,
      dataType: "date",
      operator: "GT",
      Cell: (row) => {
        let task = row.original;
        if (task.assignDate) {
          return (
            <MomentDisplay
              date={task.assignDate}
              timezone={
                this.props.currentLocation &&
                this.props.currentLocation.timezone
              }
            />
          );
        } else {
          return null;
        }
      },
    },
    {
      accessor: "acceptDate",
      Header: "Task Accept Datetime",
      default: false,
      show: false,

      filterable: false,
      sortable: true,
      dataType: "date",
      operator: "GT",
      Cell: (row) => {
        let task = row.original;
        if (task.acceptDate) {
          return (
            <MomentDisplay
              date={task.acceptDate}
              timezone={
                this.props.currentLocation &&
                this.props.currentLocation.timezone
              }
            />
          );
        } else {
          return null;
        }
      },
    },
    {
      accessor: "taskStartDate",
      Header: "Task Start Datetime",
      default: false,
      show: false,
      filterable: false,
      dataType: "date",
      operator: "GT",
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.taskStartDate) {
          return (
            <MomentDisplay
              date={task.taskStartDate}
              timezone={
                this.props.currentLocation &&
                this.props.currentLocation.timezone
              }
            />
          );
        } else {
          return null;
        }
      },
    },
    {
      accessor: "taskEndDate",
      Header: "Task End Datetime",
      default: false,
      show: false,
      filterable: false,
      dataType: "date",
      operator: "LT",
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.taskEndDate) {
          return (
            <MomentDisplay
              date={task.taskEndDate}
              timezone={
                this.props.currentLocation &&
                this.props.currentLocation.timezone
              }
            />
          );
        } else {
          return <div />;
        }
      },
    },
    {
      accessor: "lastModified",
      Header: "Last Updated Datetime",
      default: false,
      show: false,
      filterable: false,
      sortable: true,
      dataType: "date",
      operator: "GT",
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.lastModified) {
          return (
            <MomentDisplay
              date={task.lastModified}
              timezone={
                this.props.currentLocation &&
                this.props.currentLocation.timezone
              }
            />
          );
        } else {
          return null;
        }
      },
    },
    {
      accessor: "canceledDate",
      Header: "Cancel Datetime",
      default: false,
      show: false,
      filterable: false,
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.canceledDate) {
          return (
            <MomentDisplay
              date={task.canceledDate}
              timezone={
                this.props.currentLocation &&
                this.props.currentLocation.timezone
              }
            />
          );
        } else {
          return <div />;
        }
      },
    },
    {
      accessor: "trailerName",
      Header: "Trailer Name",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.trailerName ? row.original.trailerName : "";
      },
    },
    {
      accessor: "trailerNumber",
      Header: "Trailer Number",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.trailerNumber ? row.original.trailerNumber.replace(/ /g, '').toUpperCase() : "";
      },
    },
    {
      accessor: "moveFromSpotType",
      Header: "Move From Spot Type",
      default: false,
      show: false,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "moveFromZone",
      Header: "Move From Zone Name ",
      default: false,
      show: false,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "moveFromName",
      Header: "Move From Spot #",
      default: false,
      show: false,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "moveFromSpot",
      Header: "Move From Zone-Spot",
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "boxedIn",
      Header: "Boxed In",
      default: false,
      show: false,
      filterable: true,
      sortable: true,
      integerInput: true,
    },
    {
      accessor: "spotType",
      Header: "Move To Spot Type",
      default: false,
      show: false,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "moveToZone",
      Header: "Move To Zone Name",
      default: false,
      show: false,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "spotName",
      Header: "Move To Spot #",
      default: false,
      show: false,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "movedToSpot",
      Header: "Move To Zone-Spot",
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "comments",
      Header: "Comments",
      // style:{
      //   backgroundColor: this.props.currentLocation.highlightEnabled == true  ? this.props.currentLocation.backgroundColor: "",
      //   color: this.props.currentLocation.highlightEnabled == true  ? this.props.currentLocation.textColor : ""
      // },
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        const location = this.props.currentLocation;
        const taskStatus = row.original.taskStatus;
        const cellStyle = {
          backgroundColor:
            location.highlightEnabled == true &&
              taskStatus.toLowerCase() !== "complete" &&
              taskStatus.toLowerCase() !== "canceled" &&
              row.original.comments &&
              row.original.comments !== ""
              ? location.backgroundColor
              : "",
          color:
            location.highlightEnabled == true &&
              taskStatus.toLowerCase() !== "complete" &&
              taskStatus.toLowerCase() !== "canceled" &&
              row.original.comments &&
              row.original.comments !== ""
              ? location.textColor
              : "",
          display: "flex",
          height: "100%",
        };
        const targetId = `commentsCell_${row.index}`;

        return (
          <span
            style={cellStyle}
            id={targetId}
            title={this.state.Showhover ? " " : row.original.comments}
            onClick={() => {
              this.handleClickComments(row.index);
            }}
          >
            {this.state.showTooltip &&
              this.state.clickedRowIndex == row.index && (
                <Tooltip
                  style={{ color: "#fff" }}
                  target={targetId}
                  isOpen={this.state.showTooltip}
                  placement="right"
                >
                  {row.original.comments}
                </Tooltip>
              )}
            {console.log(this.state.Showhover, "titlestate")}
            {row.original.comments}
          </span>
        );
        // this.state = {
        //   showTooltip: false,
        //   clickedRowIndex: 0,
        //   Showhover: true,
        // };
      },
    },
    {
      accessor: "assetType",
      Header: "Trailer Type",
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "assetStatus",
      Header: "Trailer Status",
      default: true,
      title: "Current asset status for the task",
      filterable: true,
      sortable: true,
    },
    {
      accessor: "shipmentNumber",
      Header: "Shipment Number",
      default: true,
      filterable: true,
      sortable: true,
    },
    // {
    //   accessor: "enableArrivalTracking",
    //   Header: "Yard Arrival ID",
    //   default: true,
    //   filterable: true,
    //   sortable: true,
    //   Cell: (row) => {
    //     return row.original.enableArrivalTracking ? "Yes" : "No";
    //   },
    //   Filter: ({ filter, onChange }) => (
    //     <>
    //       <select
    //         ref={this.sealedFilterRef}
    //         onChange={(event) => {
    //           this.setState({ sealedFilter: event.target.value });
    //           onChange(event.target.value);
    //         }}
    //         style={{ width: "100%" }}
    //         value={
    //           this.state.sealedFilter ? this.state.sealedFilter : undefined
    //         }
    //       >
    //         <option value="undefined" hidden={true}>
    //           Select
    //         </option>
    //         <option value="">All</option>
    //         <option value="true">Yes</option>
    //         <option value="false">No</option>
    //       </select>
    //     </>
    //   ),
    // },
    {
      accessor: "sealed",
      Header: "Sealed",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.sealed ? "Yes" : "No";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.sealedFilterRef}
            onChange={(event) => {
              this.setState({ sealedFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.sealedFilter ? this.state.sealedFilter : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </>
      ),
    },
    {
      accessor: "sealNumber",
      Header: "Seal Number",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return (
          <span title={row.original.sealNumber}>{row.original.sealNumber}</span>
        );
      },
    },
    {
      accessor: "fuelLevel",
      Header: "Fuel Level",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.fuelLevel ? row.original.fuelLevel : "";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.fuelFilterRef}
            onChange={(event) => {
              this.setState({ fuelFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.fuelFilter ? this.state.fuelFilter : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="Empty">Empty</option>
            <option value="1/8">1/8</option>
            <option value="1/4">1/4</option>
            <option value="3/8">3/8</option>
            <option value="1/2">1/2</option>
            <option value="5/8">5/8</option>
            <option value="3/4">3/4</option>
            <option value="7/8">7/8</option>
            <option value="Full">Full</option>
          </select>
        </>
      ),
    },
    {
      accessor: "reefer",
      Header: "Reefer (On/Off )",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.reefer == true ? "Yes" : row.original.reefer == false ? "No" : "";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.reeferFilterRef}
            onChange={(event) => {
              this.setState({ reeferFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.reeferFilter ? this.state.reeferFilter : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="YES">Yes</option>
            <option value="NO">No</option>
            <option value="EMPTY">Blank</option>

          </select>
        </>
      ),
    },
    {
      accessor: "continous",
      Header: "Continuous (Yes/No)",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.continous == true ? "Yes" : row.original.continous == false ? "No" : "";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.continousFilterRef}
            onChange={(event) => {
              this.setState({ continousFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.continousFilter ? this.state.continousFilter : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="YES">Yes</option>
            <option value="NO">No</option>
            <option value="EMPTY">Blank</option>
          </select>
        </>
      ),
    },
    {
      accessor: "temperatureSetPointInc",
      Header: isF(this.props.user) ? "Temp - Set Point (℉)" : "Temp - Set Point (℃)",
      default: true,
      title:
        "Temperature set point entered for the task and the most recent reported temperature.",
      filterable: false,
      sortable: true,
      Cell: (row) => {
        return getTempSetpoint(row, this.props.user);

      },
    },
    {
      accessor: "temperatureInc",
      Header: isF(this.props.user) ? "Temp - Actual (℉)" : "Temp - Actual (℃)",
      default: true,
      title:
        "Actual Temperature entered in the create/edit task form",
      filterable: false,
      sortable: true,
      Cell: (row) => {
        return getTemp(row, null, this.props.user);
      },
    },

    {
      accessor: "temperatureDeviationInc",
      Header: isF(this.props.user) ? "Temp - Deviation (℉)" : "Temp - Deviation (℃)",
      default: true,
      title:
        "Temperature Deviation is the difference between the set point entered for the task and the most recent reported temperature.",
      filterable: false,
      sortable: true,
      Cell: (row) => {
        return getTemp(
          row,
          row.original.temperatureSetPointInc,
          this.props.user
        );
      },
    },
    {
      accessor: "huntTime",
      Header: "Hunt Time",
      default: false,
      show: false,
      filterable: false,
      sortable: true,
      Cell: (row) => {
        return getFormattedDwellDateString(row.original.huntTime);
      },
    },
    {
      accessor: "duration",
      Header: "Duration",
      default: false,
      show: false,
      filterable: false,
      sortable: true,
      Cell: (row) => {
        return getFormattedDwellDateString(row.original.duration);
      },
    },
    {
      accessor: "totalTaskTime",
      Header: "Total Task Time",
      default: false,
      show: false,
      filterable: false,
      sortable: true,
      Cell: (row) => {
        return getFormattedDwellDateString(row.original.totalTaskTime);
      },
    },
    {
      accessor: "taskShift",
      Header: "Assignee Shift",
      default: false,
      show: false,
      filterable: true,
      sortable: true,
    },
  ];

  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  noEnd(row) {
    return !row.original.taskEndDate;
  }

  notCancelled(row) {
    return row.original.taskStatus !== "Canceled";
  }

  inProgress(row) {
    return row.original.taskStatus === "InProgress";
  }
  onClickIcon(taskObj) {
    this.setState({ loaded: true });
    this.updateSpinner("Loading...");
    this.getFlagDataByTakUuid(taskObj);
  }

  getFlagDataByTakUuid(taskObj) {
    let ref = {
      system: undefined,
      reasonCode: undefined,
      description: undefined,
      responseDate: new Date(),
      taskName: undefined,
    };
    clientResponseByTaskUuid(
      taskObj.taskUuid,
      (success) => {
        if (success.code === "200") {
          ref = success.content;
          this.setState({
            isFalRefModalOpen: true,
            flagData: ref,
            loaded: false,
          });
        } else if (success.code === "1000") {
          this.setState({ loaded: false });
          showError(success.message);
        }
      },
      (error) => {
        this.setState({ loaded: false });
        showError(error.message);
        console.log(error);
      }
    );
  }

  constructor(props) {
    super(props);
    console.log("task props", props);
    this.tableRef = React.createRef();
    this.taskStatusFilterRef = React.createRef();
    const parse = JSON.parse(
      localStorage.getItem(getLocalStorageName("poweryard-tasks", props.user)),
      this.mapFromJson
    );
    this.state = {
      isLocationRequired: this.props.user.roles[0].role === "ROLE_POWERYARD_JOCKEY" ? true : false,
      forceUpdate: false,
      hideFilters: true,
      showTooltip: false,
      clickedRowIndex: 0,
      Showhover: true,
      istab: false,
    };
    if (parse) {
      this.state = {
        name: null,
        previousSearchRequest: {
          sorted: [],
          filtered: [
            { id: "start", value: startOfDay(new Date(), 7), operator: "GT" },
            { id: "end", value: endOfDay(new Date()), operator: "LT" },
            { id: "dateFilterField", value: "createdDate", operator: "EQ" },
          ],
        },
        taskStatusFilter: "undefined",
        filterStartDate: startOfDayEST(new Date()),
        filterEndDate: endOfDayEST(new Date()),
        filterDateType: "createdDate",
        dateFilterEnabled: true,
        tableSize: parse.tableSize,
        columnSizes: parse.columnSizes,
        autoRefreshEnabled: parse.autoRefreshEnabled,
        sealedFilter: parse.sealedFilter,
        reeferFilter: parse.reeferFilter,
        fuelFilter: parse.fuelFilter,
        continousFilter: parse.continousFilter,
        allLocations: [],
        showFilterCollaps: false,
        watcher: undefined,
        currentLocationObj: {},
        loaded: false,
        checkForFlag: true,
        cancelLoaded: false,
        gettingRef: null,
        isRefModalOpen: false,
        isFalRefModalOpen: false,
        selectedTask: {
          refData: {
            prNumber: null,
            prName: null,
            prAppointmentDate: null,
            prAppointmentWindow: null,
            prAppointmentType: null,
            prAppointmentPlan: null,
            prScac: null,
            prRefIdFlagDate: null,
            cancelled: null,
            prRefIdFlag: null,
            uuid: null,
            carrier: {},
            location: null,
            secondaryRef: "",
          },
        },
        flagData: {
          respondingSystem: null,
          reasonCode: null,
          Description: null,
          Datetime: null,
        },

        tooltipOpen: false,
        id: "ID_" + uuid.v4(),
      };
    } else {
      this.state = {
        name: null,
        watcher: undefined,
        previousSearchRequest: {
          sorted: [],
          filtered: [
            { id: "start", value: startOfDay(new Date(), 7), operator: "GT" },
            { id: "end", value: endOfDay(new Date()), operator: "LT" },
            { id: "dateFilterField", value: "createdDate", operator: "EQ" },
          ],
        },
        showFilterCollaps: false,
        isRefModalOpen: false,
        isFalRefModalOpen: false,
        filterStartDate: startOfDayEST(new Date()),
        filterEndDate: endOfDayEST(new Date()),
        filterDateType: "createdDate",
        tableSize: { height: "500px", width: "500px" },
        columnSizes: new Map(),
        dateFilterEnabled: true,
        allLocations: [],
        currentLocationObj: {},
        allUsers: [],
        autoRefreshEnabled: true,
        loaded: false,
        cancelLoaded: false,
        selectedTask: {
          refData: {
            prNumber: null,
            prName: null,
            prAppointmentDate: null,
            prAppointmentWindow: null,
            prAppointmentType: null,
            prAppointmentPlan: null,
            prScac: null,
            prRefIdFlagDate: null,
            cancelled: null,
            prRefIdFlag: null,
            uuid: null,
            carrier: {},
            location: null,
            secondaryRef: "",
          },
        },
        flagData: {
          system: null,
          reasonCode: null,
          description: null,
          responseDate: null,
          taskName: null,
        },
        tooltipOpen: false,
        id: "ID_" + uuid.v4(),
      };
    }

    getUsersByLocation(this.props.currentLocation.uuid)("").then((res) => {
      let userArr = res;
      userArr = userArr.filter((it) => it.enabled === true);
      userArr.forEach((element) => {
        element.assignee = element.firstname + " " + element.lastname;
        element.label = element.firstname + " " + element.lastname;
      });
      this.setState({ allUsers: userArr, usersCalled: true });
    });

    if (!this.state.filterEndDate) {
      this.state.filterEndDate = endOfDay(new Date());
    }
    if (!this.state.filterStartDate) {
      this.state.filterStartDate = startOfDay(
        new Date(new Date().setDate(new Date().getDate() - 14))
      );
    }
    if (!this.state.filterDateType) {
      this.state.filterDateType = "createdDate";
    }
    if (!this.state.tableSize) {
      this.state.tableSize = { height: "500px", width: "500px" };
    }
    console.log("Location", this.props.currentLocation);

    getDefaultCancelReasonCode(
      (res) => {
        let data = res.content;
        data.label = data.name;
        data.value = data.uuid;
        this.setState({
          defaultCancelReasonCode: data,
        });
      },
      () => {
        this.setState({
          defaultCancelReasonCode: null,
        });
      }
    );
    if (!this.state.columnSizes) {
      this.state.columnSizes = new Map();
    }
    this.tableColumns = this.tableColumns.map((col) => {
      if (this.state.columnSizes.has(col.accessor)) {
        col.width = this.state.columnSizes.get(col.accessor);
      }
      return col;
    });
    this.addOrRemoveTableColumn();
    this.addOrRemovePriorityTableColumn();
  }
  addOrRemoveTableColumn() {
    let s = this.tableColumns;
    let secRefIndex = 0;
    let statusFlagIndex = 0;
    let statusSourceIndex = 0;
    let length = s.length;
    let isRefApiOn =
      this.props.currentLocation.apiSetting !== null
        ? this.props.currentLocation.apiSetting.refernceApi
        : false;
    secRefIndex = s.findIndex((it) => it.accessor === "secondaryRef");
    statusFlagIndex = s.findIndex((it) => it.accessor === "statusFlag");
    statusSourceIndex = s.findIndex((it) => it.accessor === "statusSource");
    let secRefObj = {
      cleanHeader: "Reference Number",
      accessor: "secondaryRef",
      Header: "Reference Number",
      default: true,
      filterable: true,
      sortable: false,
      show: true,

      Cell: (row) => {
        if (row.value !== null && row.value !== "" && row.value !== undefined) {
          let v = row.original;
          return row.value.split(",").map((elem, index) => (
            <a
              href={"javascript:;"}
              onClick={() => this.openRefModal(v, elem.trim())}
            >
              {" "}
              {/* {`${elem} ${v.primaryRefName === null ? "" : `(${v.primaryRefName})`
                }`} */}
              {`${elem}`}
              {row.value.split(",").length - 1 === index ? "" : ","} <br />
            </a>
          ));
        } else {
          return "N/A";
        }
      },
    };
    let statusFlagObj = {
      show: false,
      cleanHeader: "Alert",
      accessor: "statusFlag",
      Header: "Alert",
      default: false,
      filterable: false,
      sortable: false,
      Cell: (row) => {
        let taskObj = row.original;
        return (
          taskObj.statusFlag && (
            <div className="text-center">
              {/* <i class="fas fa-flag text-danger c-pointer" ></i> */}
              {/* <Tooltip placement="right" target={`ID_${v.taskUuid}`} isOpen={this.state.tooltipOpen} toggle={this.toggle}>
            {'System Response to this Task, click for details'}
          </Tooltip> */}
              {taskObj.statusFlag && (
                <IconWithTooltip
                  isToolTip={true}
                  isIcon={true}
                  isClickAble={true}
                  toolTipTxt={"System Response to this Task, click for details"}
                  classList={"fas fa-flag text-danger c-pointer"}
                  placement="top"
                  onClick={() => {
                    this.onClickIcon(taskObj);
                  }}
                />
              )}
            </div>
          )
        );
      },
    };
    let statusSourceObj = {
      show: false,
      cleanHeader: "Alert Status Source",
      accessor: "statusSource",
      Header: "Alert Status Source",
      default: false,
      filterable: false,
      sortable: false,
      Cell: (row) => {
        let v = row.original;
        return v.statusSource !== "" ? v.statusSource : "UI";
      },
    };

    if (isRefApiOn) {
      if (secRefIndex === -1) {
        s.splice(length >= 1 ? 1 : 0, 0, secRefObj);
      } else {
        s.splice(secRefIndex, 1);
      }
      if (statusFlagIndex === -1) {
        s.splice(length >= 2 ? 2 : 0, 0, statusFlagObj);
      } else {
        s.splice(statusFlagIndex, 1);
      }
      if (statusSourceIndex === -1) {
        s.splice(length >= 3 ? 3 : 0, 0, statusSourceObj);
      } else {
        s.splice(statusSourceIndex, 1);
      }
    } else {
      if (secRefIndex > -1 && statusFlagIndex > -1 && statusSourceIndex > -1) {
        let removeIndexArr = [];
        removeIndexArr[0] = secRefIndex;
        removeIndexArr[1] = statusFlagIndex;
        removeIndexArr[2] = statusSourceIndex;

        console.log("removeINdexArr", removeIndexArr);
        for (var i = removeIndexArr.length - 1; i >= 0; i--)
          s.splice(removeIndexArr[i], 1);
      }
    }

    this.setState({ tableColumns: s });

    if (this.tableRef.current !== null && this.tableRef.current !== undefined) {
      this.tableRef.current.refreshColumns();
    }
  }
  addOrRemovePriorityTableColumn() {
    let s = this.tableColumns;

    let PriorityIndex = 0;
    let length = s.length;
    PriorityIndex = s.findIndex((it) => it.accessor === "priority");


    let priorityObj = {
      cleanHeader: "Task Priority",
      Header: "Task Priority",
      accessor: "priority",

      filterable: true,
      default: true,
      sortable: false,
      show: true,
      Cell: (row) => {
        let priorityText = "";
        let textColor = "#000";
        let backgroundColor = "inherit";

        if (row.original.priority === 1) {
          priorityText = "Low";
        } else if (row.original.priority === 3) {
          priorityText = "High";
          if (row.original.taskStatus == "Pending" ||
            row.original.taskStatus == "IsAssinged" ||
            row.original.taskStatus == "InProgress") {
            backgroundColor = "#FFFF00";
            textColor = "#fd1100";
          }



        }
        else {
          priorityText = "Normal";

        }

        return <div style={{ color: textColor, backgroundColor, width: '100%', height: '100%' }}>{priorityText}</div>;
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.enabledFilterRef}
            onChange={(event) => {
              this.setState({ enabledFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={this.state.enabledFilter}
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="3">High</option>
            <option value="2">Normal</option>
            <option value="1">Low</option>
          </select>
        </>
      ),
    };

    if (this.props.currentLocation.enablePriority == true) {
      if (PriorityIndex === -1) {
        s.splice(length >= 3 ? 3 : 0, 0, priorityObj);
      }

    } else {
      if (PriorityIndex > -1) {
        let removeIndexArr = [];
        removeIndexArr[0] = PriorityIndex;


        console.log("removeINdexArr", removeIndexArr);
        for (var i = removeIndexArr.length - 1; i >= 0; i--)
          s.splice(removeIndexArr[i], 1);
      }
    }

    this.setState({ tableColumns: s });

    if (this.tableRef.current !== null && this.tableRef.current !== undefined) {
      this.tableRef.current.refreshColumns();
    }
  }

  filterName = (inputValue) => {
    let listData = [];
    if (
      this.state.allUsers &&
      this.state.allUsers.length !== 0 &&
      this.state.usersCalled === true
    ) {
      let userArr = this.state.allUsers;
      // userArr = userArr.filter(it=> it.enabled === true);
      // userArr.forEach(element => {

      //     if (inputValue === "" || inputValue === null || inputValue === undefined) {
      //         if (element.locations.length > 0) {
      //             let userLocation = element.locations.filter(it => it.uuid === this.props.currentLocation.uuid);
      //             if (userLocation.length > 0) {
      //                 listData.push(element);
      //             }
      //         }
      //     } else {
      //         if (element.locations.length === 0) {
      //             listData.push(element);
      //         }
      //         if (element.locations.length > 0) {
      //             let userLocation = element.locations.filter(it => it.uuid === this.props.currentLocation.uuid);
      //             if (userLocation.length > 0) {
      //                 listData.push(element);
      //             }
      //         }
      //     }
      // });
      // if (inputValue !== "" && inputValue !== undefined) {
      //     getUsersByLocation(this.props.currentLocation.uuid)(inputValue).then((res)=>{
      //         let uArr = res;
      //         uArr = uArr.filter(it=> it.enabled === true);
      //         uArr.forEach(element => {
      //                 element.assignee = element.firstname + " " + element.lastname;
      //                 element.label = element.firstname + " " + element.lastname;

      //             });
      //             this.setState({ allUsers: uArr,usersCalled : true });
      //     }).then({listData = uArr});
      // } else {
      // }
      listData = userArr;
      return listData;
    } else {
      // let searchRequest = { "page": 0, "pageSize": 100, "sorted": [], "filtered": [] };
      getUsersByLocation(this.props.currentLocation.uuid)(inputValue).then(
        (res) => {
          let userArr = res;
          userArr = userArr.filter((it) => it.enabled === true);
          userArr.forEach((element) => {
            element.assignee = element.firstname + " " + element.lastname;
            element.label = element.firstname + " " + element.lastname;
          });
          this.setState({ allUsers: userArr, usersCalled: true });
        }
      );
    }
  };
  // Due to performance issue created by this call this code is revereted (PAGE NEEDS TO BE REFRESHED TO GET DISABLED USER LIST))
  // checkInput(){
  //     let searchRequest = { "page": 0, "pageSize": 100, "sorted": [], "filtered": [] };
  //         promiseUserByLocation(searchRequest,
  //             (response) => {

  //                 let userArr = response.content;

  //                 userArr = userArr.filter(it=> it.enabled === true);
  //                 userArr.forEach(element => {
  //                     element.assignee = element.firstname + " " + element.lastname;
  //                     element.label = element.firstname + " " + element.lastname;

  //                 });
  //                 this.setState({ allUsers: userArr });

  //             });
  // }
  loadOptions = (inputValue, callback) => {
    // if(inputValue !== ""){
    //     this.checkInput();
    //     setTimeout(() => {
    //         callback(this.filterName(inputValue));
    //     }, 3000);

    // } else {
    if (inputValue !== "" && inputValue !== null && inputValue !== undefined) {
      getUsersByLocation(this.props.currentLocation.uuid)(inputValue).then(
        (res) => {
          let userArr = res;
          userArr = userArr.filter((it) => it.enabled === true);
          userArr.forEach((element) => {
            element.assignee = element.firstname + " " + element.lastname;
            element.label = element.firstname + " " + element.lastname;
          });
          this.setState({ allUsers: userArr, usersCalled: true });
          callback(this.filterName(inputValue));
        }
      );
    } else {
      setTimeout(() => {
        callback(this.filterName(inputValue));
      }, 3000);
    }

    // }
  };
  triggerUpdate = () => {
    this.setState({ forceUpdate: !this.state.forceUpdate });
  };
  checkTimeAndReload() {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();

    // Check if it is 12:00 AM
    if (hours === 0 && minutes === 0 && seconds === 0) {
      // Reload the page
      window.location.reload();
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("resize", this.calculateTableSize.bind(this));
    this.calculateTableSize();

    this.timerId = setInterval(() => {
      this.checkTimeAndReload();
    }, 1000);

    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent
      );
    // console.log(isTablet)
    if (isTablet === true) {
      document.documentElement.requestFullscreen();
      const datePickers = document.getElementsByClassName(
        "react-datepicker__input-container"
      );
      Array.from(datePickers).forEach((el) =>
        el.childNodes[0].setAttribute("readOnly", true)
      );

      this.setState({ istab: true });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log('Current Time Zone:', this.props.currentLocation);
    let taskPopUpCheck = JSON.parse(localStorage.getItem("taskPopUpCheck"));
    if (taskPopUpCheck === false) {
      if (this.props.user.roles[0].role !== "ROLE_POWERYARD_JOCKEY") {
        document.getElementById("checkLocation").click();
        localStorage.setItem("taskPopUpCheck", true);
      }
      if (this.props.user.roles[0].role === "ROLE_POWERYARD_JOCKEY") {
        document.getElementById("checkShift").click();
        localStorage.setItem("taskPopUpCheck", true);
      }
    }

  }


  getLocationFireEvent(info, obj, val, user) {
    console.log("getLocationFireEvent", user);
    if (this.props.locationRequired) {
      if (navigator.geolocation) {
        let positionOptions = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 10000,
        };
        this.setState({ loaded: true });
        this.updateSpinner("Loading ...");
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.setState({
              liveLocation: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              },
            });
            this.performAction(info, obj, val, user);
          },
          (error) => {
            this.setState({ loaded: false });
            switch (error.code) {
              case error.PERMISSION_DENIED:
                showError(
                  "User denied the request for Geolocation.Please allow."
                );
                break;
              case error.POSITION_UNAVAILABLE:
                showError(
                  "Location information is unavailable.Please try again!"
                );
                break;
              case error.TIMEOUT:
                showError(
                  "The request to get user location timed out.Please refresh!"
                );
                break;
              case error.UNKNOWN_ERROR:
                showError("An unknown error occurred.");
                break;
            }
          },
          positionOptions
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
      }

    }
    else {
      this.performAction(info, obj, val, user);

    }

  }
  performAction(info, task, val, user) {
    this.setState({ loaded: true });
    this.updateSpinner(info + " ...");
    let obj = {
      taskUuid: task.taskUuid,
      taskVersion: task.taskVersion,
      ...(this.props.locationRequired && {
        coordinates: {
          latitude: this.state.liveLocation.latitude,
          longitude: this.state.liveLocation.longitude,
        },
      }),
    };

    console.log("Task Payload:", obj);


    if (val === "accept") {
      putTaskAssign(
        obj,
        (onSuccess) => {
          this.setState({ loaded: false });
          if (onSuccess.code == "1000") {
            showError(onSuccess.message);
          } else {
            if (
              user.company.enablePowerYard &&
              task.locationEnableTaskInProcess
            ) {
              this.props.history.push("/progress/" + task.taskUuid);
            } else {
              this.tableRef.current.fetchData();
            }
          }
        },
        (onFailure) => {
          if (onFailure.message) {
            showError(onFailure.message);
          }
        }
      );
    }
    if (val === "start") {
      putTaskStart(
        obj,
        (onSuccess) => {
          this.setState({ loaded: false });
          if (onSuccess.code == "1000") {
            showError(onSuccess.message);
          } else {
            if (
              user.company.enablePowerYard &&
              task.locationEnableTaskInProcess
            ) {
              this.props.history.push("/progress/" + task.taskUuid);
            } else {
              this.tableRef.current.fetchData();
            }
          }
        },
        (onFailure) => {
          if (onFailure.message) {
            showError(onFailure.message);
          }
        }
      );
    }
    if (val === "end") {
      putTaskEnd(
        obj,
        (onSuccess) => {
          this.setState({ loaded: false });
          if (onSuccess.code == "1000") {
            showError(onSuccess.message);
          } else {
            if (
              user.company.enablePowerYard &&
              task.locationEnableTaskInProcess
            ) {
              this.props.history.push("/progress/" + task.taskUuid);
            } else {
              this.tableRef.current.fetchData();
            }
          }
        },
        (onFailure) => {
          if (onFailure.message) {
            showError(onFailure.message);
          }
        }
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calculateTableSize.bind(this));
    window.scrollTo(0, 0);
    // navigator.geolocation.clearWatch(this.state.watcher);

    clearInterval(this.timerId);

    this.savePageState();
  }
  mapToJson(key, value) {
    let objectToSerialize = this[key];
    if (objectToSerialize instanceof Map) {
      return {
        dataType: "Map",
        values: Array.from(objectToSerialize.entries()),
      };
    } else {
      return value;
    }
  }
  mapFromJson(key, value) {
    if (typeof value === "object" && value !== null) {
      if (value.dataType === "Map") {
        return new Map(value.values);
      }
    }
    return value;
  }
  savePageState() {
    localStorage.setItem(
      getLocalStorageName("poweryard-tasks", this.props.user),
      JSON.stringify(
        {
          previousSearchRequest: this.state.previousSearchRequest,
          //taskStatusFilter: this.state.taskStatusFilter,
          filterStartDate: this.state.filterStartDate,
          filterEndDate: this.state.filterEndDate,
          filterDateType: this.state.filterDateType,
          showFilterCollaps: this.state.showFilterCollaps,
          dateFilterEnabled: this.state.dateFilterEnabled,
          columnSizes: this.state.columnSizes,
          autoRefreshEnabled: this.state.autoRefreshEnabled,
        },
        this.mapToJson
      )
    );
  }
  calculateTableSize = () => {
    let size = getTableSize("power-yard-task-page", "power-yard-tasks-header");
    this.setState({ tableSize: { height: size.height, width: size.width } });
  };

  render() {
    return (
      <>
        {this.state.selectedTask !== undefined && (
          <ReferenceModal
            selectedTask={this.state.selectedTask}
            currentLocation={this.props.currentLocation}
            cancel={() => this.setState({ isRefModalOpen: false })}
            isRefModalOpen={this.state.isRefModalOpen}
          />
        )}
        {this.state.flagData !== undefined && (
          <FlagModal
            flagData={this.state.flagData}
            currentLocation={this.props.currentLocation}
            cancel={() => this.setState({ isFalRefModalOpen: false })}
            isRefModalOpen={this.state.isFalRefModalOpen}
          />
        )}

        <Loader show={this.state.loaded} message={this.spinner}>
          <div id="power-yard-task-page">
            <CancelTaskModal
              open={this.state.cancelModalOpen}
              loaded={this.state.loaded}
              defaultReasonCode={this.state.defaultCancelReasonCode}
              currentLocation={this.props.currentLocation}
              onSave={(reasonCode) => {
                this.setState({ loaded: true });
                this.updateSpinner("Canceling ... ");
                const data = this.props.position ? this.props.position : {};
                data.cancelReasonCode = reasonCode;
                let o = {
                  uuid: this.state.cancelTask.taskUuid,
                  latitude: data.latitude,
                  longitude: data.longitude,
                  cancelReason: data.cancelReasonCode,
                };
                putTaskCancel(
                  o,
                  () => {
                    this.tableRef.current.fetchData();
                    showInfo("Task Canceled.");
                    this.setState({ cancelModalOpen: false });
                  },
                  (err) => {
                    showError(err);
                    this.setState({ cancelLoaded: false });
                  }
                );
              }}
              onCancel={() => this.setState({ cancelModalOpen: false })}
            />
            <div className="column-wrapper text-left">
              <div className="page-header" id="power-yard-tasks-header">
                <Row>
                  <Col>
                    <h1>
                      Tasks &nbsp;{" "}
                      {
                        !this.props.currentLocation.enableArrivalTracking && (
                          <Button
                            onClick={() => this.props.history.push("/task/new")}
                          >
                            New Task
                          </Button>
                        )}

                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {this.props.user.company.enablePowerYardPro === true &&
                        this.props.currentLocation.enableYardCheck && (
                          <Button
                            onClick={() =>
                              this.props.history.push("/poweryard-onsite")
                            }
                          >
                            Yard Check
                          </Button>
                        )}
                    </h1>
                  </Col>
                  <Col className={"text-left"}>
                    <ChangeShiftFormField
                      yardCheckActive={this.props.user.onYardCheck}
                      onChange={(shift, location) => {
                        if (shift) {
                          if (this.props.currentShift !== shift) {
                            this.props.setCurrentShift(shift);
                            if (shift) {
                              let data = this.props.user;
                              data.shift = shift.value;
                              data.version = data.version + 1;
                              data.password = "NOPASSWORD";
                              postUserDefaultShift(data);
                            }
                          }
                        }
                        if (location) {
                          if (
                            this.props.currentLocation.uuid !== location.uuid
                          ) {
                            this.props.setCurrentLocation(location);
                            if (location && location.uuid) {
                              postUserDefaultLocation(location.uuid);
                            }
                          }
                        }
                      }}
                      currentShift={this.props.currentShift}
                      currentLocation={this.props.currentLocation}
                    />
                  </Col>
                  <Col className={"text-right"}>
                    <ChangeLocationFormField
                      yardCheckActive={this.props.user.onYardCheck}
                      onChange={(location) => {
                        if (this.props.currentLocation.uuid !== location.uuid) {
                          this.props.setCurrentLocation(location);
                          if (location && location.uuid) {
                            postUserDefaultLocation(
                              location.uuid,
                              (success) => {
                                console.log("Location changed",this.props.currentLocation);

                                this.addOrRemoveTableColumn();
                                this.addOrRemovePriorityTableColumn();

                                this.tableRef.current.requestPageSearch();
                              },
                              (err) => { }
                            );
                            // setTimeout(() => {
                            //   window.location.reload();
                            // }, 500);
                          }
                        }
                      }}
                      currentLocation={this.props.currentLocation}
                    />
                    <Button
                      onClick={() => {
                        if (this.state.showFilterCollaps === true) {
                          this.setState({ showFilterCollaps: false });
                        } else {
                          this.setState({ showFilterCollaps: true });
                        }
                      }}
                    >
                      <i
                        className={
                          this.state.showFilterCollaps === true
                            ? "fa fa-chevron-up"
                            : "fa fa-chevron-down"
                        }
                      />
                    </Button>
                  </Col>
                </Row>
              </div>

              <PaginationFilterTable
                setloaded={(check) => {
                  this.setState({ loaded: check });
                  this.updateSpinner("");
                }}
                openFiltersCollaps={this.state.showFilterCollaps}
                showEditColumns
                autoRefreshEnabled={this.state.autoRefreshEnabled}
                autoRefreshInterval={this.roleBasedInterval()}
                user={this.props.user}
                style={{
                  maxHeight: this.state.tableSize.height,
                  maxWidth: this.state.tableSize.width,
                  minHeight: "120px",
                }}
                ref={this.tableRef}
                get={downloadTasks}
                successCallback={(response) => {
                  this.setState({ inTask: response.inTask, fetched: true });
                }}
                baseFilter={[
                  {
                    id: "taskLocationUuid",
                    value: this.props.currentLocation
                      ? this.props.currentLocation.uuid
                      : "null",
                  },
                ]}
                filtered={this.state.previousSearchRequest.filtered}
                onFilteredChange={(filter) => {
                  const delayFilters = ['taskStatus', 'fuelLevel', 'priority', 'boxedIn', 'sealed', 'reefer', 'continous'];
                  let psr = { ...this.state.previousSearchRequest };
                  psr.filtered = { ...this.state.filtered };
                  psr.filtered = filter;
                  const hasDelayFilter = psr.filtered.some(filter => delayFilters.includes(filter.id));
                  this.setState({ previousSearchRequest: psr }, () => {
                    if (hasDelayFilter) {
                      this.tableRef.current.requestPageSearch();
                    } else {
                      setTimeout(() => {
                        this.tableRef.current.requestPageSearch();
                      }, 3000); // 3000 milliseconds = 4 seconds
                    }
                  });

                }}
                clearFilter={(resetAll) => {
                  if (resetAll) {
                    let psr = { ...this.state.previousSearchRequest };
                    psr.filtered = [];
                    // this.taskStatusFilterRef.current.value = undefined;

                    this.setState(
                      {
                        filterStartDate: startOfDayEST(new Date()),
                        filterEndDate: endOfDayEST(new Date()),
                        dateFilterEnabled: true,
                        previousSearchRequest: psr,
                      },
                      () => {
                        // this.tableRef.current.restoreColumns(true);
                        this.tableRef.current.setState({
                          page: 0,
                          forcePage: 0,
                        });
                        this.updateProperty(
                          "start",
                          this.state.filterStartDate
                        );
                        this.updateProperty("end", this.state.filterEndDate);
                        this.updateProperty(
                          "dateFilterField",
                          this.state.filterDateType
                        );
                      }
                    );
                  } else {
                    let psr = { ...this.state.previousSearchRequest };
                    psr.filtered = psr.filtered.filter(
                      (f) =>
                        f.id === "start" ||
                        f.id === "end" ||
                        f.id === "dateFilterField"
                    );
                    // this.taskStatusFilterRef.current.value = undefined;
                    this.setState(
                      {
                        previousSearchRequest: psr,
                      },
                      () => {

                        if (this.state.hideFilters == false) {
                          this.updateSpinner("");
                          this.tableRef.current.requestPageSearch()
                        }
                      }
                    );
                  }
                }}
                setFilterToogle={(value) => {
                  this.setState({
                    hideFilters: value,

                  });
                }}
                defaultSorted={[]}
                stateStorage={"poweryard-tasks"}
                columns={this.tableColumns}
                onFilterHide={() => {
                  this.setState({
                    taskStatusFilter: "undefined",
                    enabledFilter: "undefined",
                    sealedFilter: "undefined",
                    reeferFilter: "undefined",
                    fuelFilter: "undefined",
                    continousFilter: "undefined",
                  });
                }}
                onChange={(state) => {
                  this.setState({
                    previousSearchRequest: {
                      dateFilter: state.dateFilter,
                      itemsPerPage: state.itemsPerPage,
                      page: state.page,
                      pageSize: state.pageSize,
                      sorted: state.sorted,
                      filtered: state.filtered,
                    },
                  });
                }}
                onResizedChange={(customizedColumns, event) => {
                  let columnSizes = this.state.columnSizes
                    ? this.state.columnSizes
                    : new Map();
                  customizedColumns.forEach((col) => {
                    columnSizes.set(col.id, col.value);
                  });
                  this.setState({ columnSizes: columnSizes });
                }}
              >
                <TableDateRangePicker
                  ref={this.tableRef}
                  showTaskSelector={true}
                  start={this.state.filterStartDate}
                  end={this.state.filterEndDate}
                  dateType={this.state.filterDateType}
                  filterEnabled={this.state.dateFilterEnabled}
                  onDateFilterEnable={(enabled) => {
                    this.setState({ dateFilterEnabled: enabled });
                    if (enabled) {
                      this.tableRef.current.setState({ page: 0, forcePage: 0 });
                      this.updateProperty("start", this.state.filterStartDate);
                      this.updateProperty("end", this.state.filterEndDate);
                      this.updateProperty(
                        "dateFilterField",
                        this.state.filterDateType
                      );
                    } else {
                      this.removeFilter("start");
                      this.removeFilter("end");
                      this.removeFilter("dateFilterField");
                    }
                    this.tableRef.current.requestPageSearch();
                  }}
                  onStartDateChange={(date) => {
                    this.updateProperty("start", date);
                    this.setState({ filterStartDate: date });
                    this.tableRef.current.requestPageSearch();
                  }}
                  onEndDateChange={(date) => {
                    this.updateProperty("end", date);
                    this.setState({ filterEndDate: date });
                    this.tableRef.current.requestPageSearch();
                  }}
                  onDateTypeChange={(val) => {
                    this.updateProperty("dateFilterField", val);
                    this.setState({ filterDateType: val });
                    this.tableRef.current.requestPageSearch();
                  }}
                />
              </PaginationFilterTable>
            </div>
          </div>
        </Loader>
      </>
    );
  }

  removeFilter(id) {
    let previousSearchRequest = Object.assign(
      {},
      this.state.previousSearchRequest
    );
    let filtered = previousSearchRequest.filtered;
    if (filtered == null) {
      filtered = [];
    }
    for (let i = 0; i < filtered.length; i++) {
      if (filtered[i].id === id) {
        filtered.splice(i, 1);
        break;
      }
    }
    previousSearchRequest.filtered = filtered;
    this.setState({ previousSearchRequest: previousSearchRequest }, () => {
      this.savePageState();
    });
  }
  updateProperty(id, value) {
    let previousSearchRequest = Object.assign(
      {},
      this.state.previousSearchRequest
    );
    let filtered = previousSearchRequest.filtered;
    let found = false;
    if (filtered == null) {
      filtered = [];
    }
    for (const filter of filtered) {
      if (filter.id === id) {
        filter.value = value;
        found = true;
      }
    }

    if (!found) {
      if (id === "start") {
        filtered.push({ id: id, value: value, operator: "GT" });
      } else if (id === "end") {
        filtered.push({ id: id, value: value, operator: "LT" });
      } else if (id === "filterDateType") {
        filtered.push({ id: id, value: value, operator: "EQ" });
      } else {
        filtered.push({ id: id, value: value, operator: "EQ" });
      }
    }
    previousSearchRequest.filtered = filtered;
    this.setState(
      {
        previousSearchRequest: previousSearchRequest,
      },
      () => {
        this.savePageState();
      }
    );
  }
  roleBasedInterval() {
    let s = this.props.user;
    let index = s.roles.findIndex((it) => it.role === "ROLE_POWERYARD_JOCKEY");
    if (index > -1) {
      return 30000;
    } else {
      return 120000;
    }
  }
  getProperty(field) {
    let previousSearchRequest = Object.assign(
      {},
      this.state.previousSearchRequest
    );
    let filtered = previousSearchRequest.filtered;
    if (filtered) {
      for (const filter of filtered) {
        if (filter && field) {
          if (filter.id === field) {
            return filter.value;
          }
        }
      }
    }
  }
}
Tasks.propTypes = {
  currentLocation: PropTypes.object,
  currentShift: PropTypes.string,
  position: PropTypes.object,
  user: PropTypes.object,
  setCurrentLocation: PropTypes.func,
  setCurrentShift: PropTypes.func,
};

export default withRouter(Tasks);