import React from 'react';

const ErrorPage = () => {
  const pageStyle = {
    backgroundColor: '#000000',
    backgroundImage: 'radial-gradient(#11581E, #041607), url("https://media.giphy.com/media/oEI9uBYSzLpBK/giphy.gif")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh',
    fontFamily: "'Inconsolata', Helvetica, sans-serif",
    fontSize: '1.5rem',
    color: 'rgba(128, 255, 128, 0.8)',
    textShadow: '0 0 1ex rgba(51, 255, 51, 1), 0 0 2px rgba(255, 255, 255, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden'
  };

  const noiseStyle = {
    pointerEvents: 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundImage: 'url("https://media.giphy.com/media/oEI9uBYSzLpBK/giphy.gif")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    zIndex: -1,
    opacity: 0.02
  };

  const overlayStyle = {
    pointerEvents: 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'repeating-linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0) 100%)',
    backgroundSize: 'auto 4px',
    zIndex: 1
  };

  const overlayBeforeStyle = {
    content: '""',
    pointerEvents: 'none',
    position: 'absolute',
    display: 'block',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    backgroundImage: 'linear-gradient(0deg, transparent 0%, rgba(32, 128, 32, 0.2) 2%, rgba(32, 128, 32, 0.8) 3%, rgba(32, 128, 32, 0.2) 3%, transparent 100%)',
    backgroundRepeat: 'no-repeat',
    animation: 'scan 7.5s linear 0s infinite'
  };

  const terminalStyle = {
    boxSizing: 'inherit',
    position: 'absolute',
    height: '100%',
    width: '1000px',
    maxWidth: '100%',
    padding: '4rem',
    textTransform: 'uppercase'
  };

  const outputStyle = {
    color: 'rgba(128, 255, 128, 0.8)',
    textShadow: '0 0 1px rgba(51, 255, 51, 0.4), 0 0 2px rgba(255, 255, 255, 0.8)'
  };

  const linkStyle = {
    color: '#fff',
    textDecoration: 'none'
  };

  const errorcodeStyle = {
    color: 'white'
  };

  return (
    <div style={pageStyle}>
      <div style={noiseStyle}></div>
      <div style={overlayStyle}>
        <div style={overlayBeforeStyle}></div>
      </div>
      <div style={terminalStyle}>
        <p style={outputStyle}>The page you are trying to access is not available for this location.</p>
        <p style={outputStyle}>Please navigate to the <a href="#1" style={linkStyle}>Task Page</a> and <a href="#2" style={linkStyle}>Change Location</a>.</p>

      </div>
    </div>
  );
};

export default ErrorPage;